.navbar-container {
  background-color: #fcfcfc;

  border-bottom-color: rgba(0, 0, 0, 0.55);
  border-bottom-style: solid;
  border-bottom-width: 1px;

  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.55);

  padding: 0.5rem;
  width: 100%;
  z-index: 610;

  h1 {
    //letter-spacing: 0.33em;
    font-size: 3.5em;
    //letter-spacing: -0.1em;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 0px;
    text-align: center;
    user-select: none;

    .title-first {
      font-family: Ofelia-Thin, sans-serif;
    }

    .title-second {
      font-family: Ofelia-Light, sans-serif;
    }
  }

  //Some fine tuning...
  //Tablet-ish sizes
  @media (min-width: 481px) and (max-width: 768px) {
    h1 {
      font-size: 3.5em;
    }
  }

  //Phone-ish sizes
  @media (min-width: 320px) and (max-width: 480px) {
    h1 {
      font-size: 2.5em;
    }
  }
}
