.home-container {
  a {
    color: #999999;
  }

  a:hover {
    color: #afafaf;
  }
}

.content-flex {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .content-flex {
    flex-direction: column;

    #profile-image {
      margin-left: auto;
      margin-right: auto;
      width: 70%;
    }

    .home-text {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }
}

//Desktop and larger screen sizes
@media screen and (min-width: 769px) {
  .content-flex {
    flex-direction: row;

    .home-text {
      width: 45%;
    }

    #profile-image {
      margin-left: auto;
      margin-right: auto;
      width: 35%;
      margin-top: 8px;
    }
  }
}
