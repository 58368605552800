.employment-container {
  margin-bottom: 12px;
  width: 100%;

  .employer-header {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin-bottom: 8px;

    .employer-name {
      font-size: 1.1em;
      margin: 0;
      width: 60%;
    }

    .job-title {
      font-style: italic;
      font-size: 0.8em;
      margin: 0;
    }

    .employer-dates {
      font-size: 0.8em;
      margin: 0;
      width: 100%;
    }
  }

  .job-functions {
    list-style: square inside;
    padding-left: 12px;
    width: 100%;

    .job-function {
      display: block;
      margin-bottom: 8px;
    }
  }
}
