.bottom-bar-container {
  align-items: center;
  bottom: 18px;
  display: flex;
  flex-direction: row;
  gap: 88px;
  justify-content: center;
  margin: auto;
  left: 0;
  padding: 4px;
  position: fixed;
  right: 0;
  width: 80%;
}
