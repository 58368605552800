#menu-toggle {
  position: absolute;
  right: 8px;

  //Some fine tuning...
  //Tablet-ish sizes
  @media (min-width: 481px) and (max-width: 768px) {
    top: 26px;
  }

  //Phone-ish sizes
  @media (min-width: 320px) and (max-width: 480px) {
    top: 15px;
  }
}

.menu-toggle-container {
  display: block;
  height: 48px;
  position: relative;
  width: 48px;

  .topbar {
    top: 16px;
    transform: rotate(0deg);
  }

  .bottombar {
    top: 32px;
    transform: rotate(0deg);
  }

  .bar {
    background-color: rgba(0, 0, 0, 0.55);
    display: block;
    height: 2px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 34px;
    transition:
      top 0.18s ease-in 0.18s,
      transform 0.18s ease-in 0s;
  }
}

.menu-toggle-container.expanded {
  .topbar {
    top: 24px;
    transform: rotate(45deg);
  }

  .bottombar {
    top: 24px;
    transform: rotate(-45deg);
  }

  .bar {
    transition:
      top 0.18s ease-in 0s,
      transform 0.18s ease-in 0.18s;
  }
}
