@import "components/header/header.scss";
@import "components/header/header-menu/header-menu.scss";
@import "components/header/header-menu/menu-toggle/menu-toggle.scss";
@import "components/accessibility-tools/accessibility-menu.scss";
@import "components/education/education.scss";
@import "components/employment/employment.scss";
@import "components/BottomBar/bottom-bar.scss";
@import "components/common/IconButton/icon-button.scss";
@import "components/ProjectLink/projectLink.scss";
@import "components/Project/project.scss";

@import "sections/home.scss";
@import "sections/resume.scss";
@import "sections/projects.scss";

//Font families
@font-face {
  font-family: "Atkinson-Hyperlegible";
  src:
    url("resources/font/atkinson/Atkinson-Hyperlegible-Regular-102a.woff2")
      format("woff2"),
    url("resources/font/atkinson/Atkinson-Hyperlegible-Regular-102.woff")
      format("woff");
}
@font-face {
  font-family: "Open-Dyslexic";
  //src: url('resources/font/open-dyslexic/OpenDyslexic-Regular.woff') format('woff2');
  src: url("resources/font/open-dyslexic/OpenDyslexic-Regular.woff")
    format("woff");
}

@font-face {
  font-family: "Ofelia-Light";
  src: url("resources/font/ofelia/OfeliaDisplay-Light.woff2") format("woff2");
  src: url("resources/font/ofelia/OfeliaDisplay-Light.woff") format("woff");
}

@font-face {
  font-family: "Ofelia-Thin";
  src: url("resources/font/ofelia/OfeliaDisplay-Thin.woff2") format("woff2");
  src: url("resources/font/ofelia/OfeliaDisplay-Thin.woff") format("woff");
}

@font-face {
  font-family: "Polaris";
  src: url("resources/font/polaris/GalaxiePolaris-Book.woff2") format("woff2");
  src: url("resources/font/polaris/GalaxiePolaris-Book.woff") format("woff");
}

body {
  background-color: #f9f9f9;
}

#body-container {
  background-color: #f9f9f9;
  color: rgba(0, 0, 0, 0.55);
  overflow-x: hidden;
  width: "100%";
}

.content-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

//Some fine tuning...
//Desktop-ish sizes
@media (min-width: 769px) {
  .content-container {
    width: 80%;
  }
}
