.school-name {
  font-weight: bold;
}

.edu-ul {
  display: flex;
  flex-direction: column;

  .degree-listing {
    font-size: 0.9em;
    margin-bottom: 4px;
    margin-top: 4px;
    padding-left: 12px;
    width: 100%;
  }
}
