$border-color: rgba(0, 0, 0, 0.55);

.header-link {
  color: rgba(0, 0, 0, 0.55);
  display: block;
  font-family: Ofelia-Light, sans-serif;
  font-size: 1.1em;
  padding: 0.5rem 1rem;
  text-decoration: none;
  text-transform: uppercase;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.opendyslexic .header-link {
  font-family: Open-Dyslexic, serif;
}

.header-link:hover {
  color: #000000;
}

.wide-view {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 60%;

  .header-link {
    text-align: center;
    width: 49%;
  }
  .header-link.current-page {
    font-weight: bold;
  }
}

.mobile-view {
  border-left-color: $border-color;
  border-left-style: solid;
  border-left-width: 1px;

  box-shadow: -1px 2px 6px $border-color;
  height: 100vh;

  overflow: hidden;
  position: absolute;
  right: 0;
  top: 59px;
  transition: width 0.36s ease-in 0s;
  width: 0%;
  z-index: 600;

  .header-link {
    border-bottom-color: $border-color;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: block;
    padding: 18px;
    width: 100%;
  }
}

//Some fine tuning...
//Tablet-ish sizes
@media (min-width: 481px) and (max-width: 768px) {
  .mobile-view {
    top: 110px;
    background-color: #e0e0e0;
  }

  .mobile-view.expanded {
    width: 50%;
  }
}

//Phone-ish sizes
@media (min-width: 320px) and (max-width: 480px) {
  .mobile-view {
    top: 86px;
    background-color: #e0e0e0;
  }

  .mobile-view.expanded {
    width: 80%;
  }
}
