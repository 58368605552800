.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;

  .project-title {
    font-size: 3.6em;
    margin-bottom: 8px;
  }

  .project-content-container {
    align-self: flex-start;

    a {
    }

    h3 {
      border-bottom-color: #bababa;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 16px;
      text-align: center;
      width: 80%;
    }

    h4 {
      font-size: 1em;
    }
  }
}
