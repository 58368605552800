.project-link-container {
  background-color: #cccccc;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  height: 450px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  text-align: center;
  user-select: none;
  width: 45%;
}
