.accessibility-menu {
  .access-menu-icon {
    border-color: rgba(0, 0, 0, 0.55);
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    font-size: 42px;
    user-select: none;
  }

  #accessibility-checkbox:checked ~ .access-menu-icon {
    border-color: #8888ff;
    color: #8888ff;
  }

  #accessibility-checkbox {
    display: none;
  }

  .access-menu-icon:hover {
    cursor: pointer;
  }

  .menu-form-container {
    background-color: #aaaaaa;
    overflow: hidden;

    .access-menu-form input {
      margin-bottom: 18px;
    }
  }

  form {
    padding: 8px;

    input {
      margin-left: 18px;
    }

    label {
      margin-left: 8px;
    }
  }

  #default-label {
    font-family: sans-serif;
  }

  #atkinson-label {
    font-family: Atkinson-Hyperlegible;
  }

  #dyslexie-label {
    font-family: Open-Dyslexic;
  }
}
//Desktop and larger screen sizes
@media screen and (min-width: 769px) {
  .accessibility-menu {
    position: fixed;
    bottom: 48px;
    right: 48px;

    .access-menu-icon {
      background-color: #f9f9f9;
      z-index: 700;
    }

    .menu-form-container {
      border-radius: 6px;
      border-bottom-right-radius: 0px;
      bottom: 44px;
      box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.55);
      right: 44px;
      position: absolute;
      transition:
        height 0.22s ease-in 0s,
        width 0.22s ease-in 0s;
    }

    //Collapsed
    .container-collapsed {
      height: 0vh;
      width: 0px;
    }
    //Expanded
    .container-expanded {
      height: 30vh;
      width: 220px;
    }

    h2 {
      text-align: center;
      user-select: none;
    }
  }
}

//Mobile (mixing tablet and phone sizes)
@media screen and (max-width: 768px) {
  .accessibility-menu {
    margin-top: 8px;

    .access-menu-icon {
      margin-bottom: 12px;
      margin-left: 18px;
      margin-top: 18px;
    }

    .menu-form-container {
      transition: height 0.22s ease-in 0s;
      width: 100%;
    }

    //Collapsed
    .container-collapsed {
      height: 0vh;
    }

    //Expanded
    .container-expanded {
      height: 30vh;
    }
  }
}
