.resume-section {
  margin-bottom: 5vh;
  width: 100%;

  .resume-section-header {
    border-bottom-color: rgba(0, 0, 0, 0.55);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    font-family: "Ofelia-Light";
    font-size: 1.1em;
    padding-bottom: 12px;
    text-transform: uppercase;
    width: 100%;

    .resume-icon {
      transform: translateY(2px);
    }

    .resume-section-title {
      margin-left: 8px;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;

    .resume-skill {
      font-size: 0.9em;
      margin-bottom: 4px;
      margin-right: 18px;
      margin-top: 4px;
      text-align: left;
      width: 23%;
    }
  }

  @media screen and (min-width: 769px) {
    ul {
      .resume-skill {
        margin-right: 18px;
        text-align: left;
        width: 23%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    ul {
      .resume-skill {
        margin-right: 8px;
        text-align: left;
        width: 30%;
      }
    }
  }
}
